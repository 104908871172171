.product-page-heading {
    background-color: $bf-blue;
    width: 100%;
    color: white;

    h1 {
        font-size: 40px;
        text-align: center;
        padding: 30px;
        margin: 0;
        font-weight: 200;
        text-transform: uppercase;
    }

    @media (max-width: 850px) {
        h1 {
            font-size: 30px;
        }
    }
}




.product-page-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .product-page-filter {
        background-color: $grey-bg;
        width: 100%;

        .product-page-filter-content {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            max-width: 900px;
            padding: 15px;
            margin: 0 auto;

            h4{
                text-transform: uppercase;
                font-size: 20px;
                font-weight: 500;
                margin: 0;
            }

            button {
                border: none;
                background-color: transparent;
                padding: 0;
                margin: 0;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 4px;
                    background: $bf-green;
                    transition: width 0.3s;
                }

                &:hover::after {
                    width: 100%;
                    transition: width 0.3s;
                }

                &.active {
                    color: $bf-green;
                    font-weight: 600;
                }
            }
        }
    }

    .filter-mobile{
        display: none;
    }

    .products-section {
        padding: 50px 0px;

        .products-grid {
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            justify-content: center;
            margin: 0 auto;
            max-width: 900px;

            .product-card {
                width: 270px;
                text-decoration: none;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
        
                img {
                    max-width: 270px;
                    height: 203px;
                }
        
                .product-card-heading {
                    width: 100%;
                    height: 90px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0px 20px;
                    background-color: $bf-blue;
                    color: white;
        
                    h3 {
                        font-size: 22px;
                    }
        
                    p {
                        font-weight: 100;
                        margin: 0;
                    }
                }
        
                .product-card-price {
                    width: 100%;
                    color: black;
                    background-color: $grey-bg;
                    height: 250px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
        
                    h3 {
                        font-weight: 600;
                        margin: 0;
                        font-size: 25px;
                    }
        
                    p {
                        font-weight: 100;
                        margin-bottom: 80px;
                    }
        
                    button {
                        padding: 10px 25px;
                        border: none;
                        font-weight: 400;
                        color: white;
                        background-color: $bf-green;
                        box-shadow: 0px 0px 5px 0px #808080ab;
                        transition: all 0.1s ease;

                        &:hover {
                            transition: all 0.1s ease;
                            background-color: $bf-blue;
                        }

                    }
                }
            }
        }
    }

    @media (max-width: 1180px) {

        .products-section {

            .products-grid {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    @media (max-width: 910px) {

        .products-section {

            .products-grid {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media (max-width: 850px) {

        .product-page-filter {
            display: none;
        }

        .filter-mobile {
            display: block;
            padding-top: 20px;

            .mb-3 {
                margin-bottom: 0 !important;

                .dropdown-menu {
                    background-color: $grey-bg;
                    
                    &.show {
                        display: flex;
                        flex-direction: column;
                        padding: 10px;
                    }

                    button {
                        background: transparent;
                        border: none;
                        margin: 5px 0px;
                        border-bottom: 2px solid $bf-green;

                        &.active {
                            color: $bf-green;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 600px) {

        .products-section {
            padding: 50px 15px;

            .products-grid {
                gap: 10px;
                .product-card {
                    width: 170px;

                    img {
                        max-width: 170px;
                        max-height: 135px;
                    }

                    .product-card-heading {
                        h3 {
                            font-size: 16px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }

                    .product-card-price {
                        h3 {
                            font-size: 18px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                    
                }
            }
        }
    }
}