.footer{
    background-color: $grey-bg;
    border-top: 4px solid darkgray;

    .upper-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 0 auto;
        max-width: 1200px;
        padding: 40px 0px;

        .footer-img{
            width: 200px;
            img{
                max-width: 200px;
            }
        }

        .footer-kontakt-oss, .footer-frakt, .footer-nyheter {
            width: 200px;
            
            h4{
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 15px;
            }

            p {
                font-size: 15px;
                line-height: 25px;
            }
        }
        
        .footer-kontakt-oss {
            width: 250px;
        }
    }

    .bottom-footer {
        text-align: center;
        background-color: $grey-bg;
        color: gray;
        font-size: 12px;
        
        p {
            padding: 10px 0px;
            margin: 0;
        }
    }

    @media (max-width: 950px) {

        .upper-footer {
            flex-direction: column;
            align-items: center;
            text-align: center;


            .footer-kontakt-oss,
            .footer-frakt,
            .footer-nyheter {
                
                h4 {
                    padding-top: 10px;
                }
            }
        }
    }
}