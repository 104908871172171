.about-sec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .about-heading{
        background-color: $bf-blue;
        color: white;
        text-align: center;
        text-transform: uppercase;

        h1 {
            font-size: 40px;
            text-align: center;
            padding: 30px;
            margin: 0;
            font-weight: 200;
            text-transform: uppercase;
        }

        @media (max-width: 850px) {
            h1 {
                font-size: 30px;
            }
        }
    }

    .about-beggining {
        display: flex;
        flex-direction: row;
        padding: 60px 30px 100px 30px;
        max-width: 1200px;
        margin: 0 auto;

        .about-beggining-img {
            width: 40%;
            
            img {
                max-width: 400px;
                padding-top: 25px;
            }
        }

        .about-beggining-content {
            width: 60%;
            padding-top: 20px;

            h2 {
                text-transform: uppercase;
                padding-bottom: 20px;
            }

            p{
                line-height: 40px;
                font-size: 15px;
            }
        }

        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 30px;

            .about-beggining-img {
                img {
                    width: 100%;
                    padding-bottom: 10px;
                }
            }
        }

        @media (max-width: 700px) {
            padding: 15px;

            .about-beggining-img {
                width: 90%;
            }

            .about-beggining-content {
                width: 90%;

                h2 {
                    font-size: 25px;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }

    .about-main {
        display: flex;
        flex-direction: row-reverse;
        padding: 70px 30px 50px 30px;
        max-width: 1200px;
        margin: 0 auto;

        .about-main-img {
            width: 40%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            img {
                max-width: 400px;
            }

            .vertical-line {
                content: '';
                background: black;
                width: 3px;
                height: 150px;
                margin: 0px 20px;
            }
        }

        .about-main-content {
            width: 60%;
            padding-right: 65px;

            h2 {
                text-transform: uppercase;
                padding-bottom: 20px;
            }

            p {
                line-height: 40px;
            }
        }

        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 30px;

            .about-main-img {
                width: 90%;
                img {
                    padding-bottom: 15px;
                    width: 100%;
                }
                
            }

            .about-main-content{
                padding-right: 0;
            }
        }

        @media (max-width: 700px) {
            .about-main-content {
                width: 100%;

                h2 {
                    font-size: 25px;
                }
            
                p {
                    font-size: 14px;
                }

            }
        }

    }

    .about-end {
        display: flex;
        flex-direction: row;
        padding: 50px 30px;
        max-width: 1200px;
        margin: 0 auto;

        .about-end-grid {
            width: 40%;
            padding-right: 65px;
            display: grid;
            gap: 10px;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }

        .about-end-content {
            width: 60%;

            h2 {
                text-transform: uppercase;
            }
            
            p{
                line-height: 40px;
            }
        }

        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
            text-align: center;

            .about-end-grid{
                padding-bottom: 15px;
            }
        }

        @media (max-width: 700px) {
            .about-end-content {
                width: 100%;

                h2 {
                    font-size: 25px;
                }

                p {
                    font-size: 14px;
                }

            }
        }
    }
}