.news-page {
    color: white;
    background-color: $news-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    h1 {
        font-size: 40px;
        font-weight: 200;
        margin: 0;
        text-transform: uppercase;
    }

    @media (max-width: 850px) {
        h1 {
            font-size: 30px;
        }
    }

    .news-section {

        .news-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 1200px;
            padding: 40px;
            width: 100%;

            h2 {
                text-transform: none;
                font-weight: 500;
                font-size: 35px;
            }

            .news-line {
                content: "";
                width: 100%;
                height: 3px;
                background: white;
                margin: 30px 0px;
            }

            .date-line {
                content: "";
                width: 60px;
                height: 3px;
                background: white;
                margin: 30px 0px;
            }

            .news-date {
                margin-bottom: 30px;
            }

            .news-images {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .news-main-img {

                    #myImg {
                        max-width: 700px;
                        max-height: 500px;
                        height: auto;
                        width: auto;
                        cursor: pointer;
                        transition: 0.3s;
                
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    

                    .modal {
                        display: flex;
                        position: fixed;
                        /* Stay in place */
                        z-index: 21;
                        /* Sit on top */
                        padding-top: 100px;
                        /* Location of the box */
                        left: 0;
                        top: 0;
                        width: 100%;
                        /* Full width */
                        height: 100%;
                        /* Full height */
                        background-color: rgb(0, 0, 0);
                        /* Fallback color */
                        background-color: rgba(0, 0, 0, 0.9);
                        /* Black w/ opacity */


                        .modal-content {
                            margin: auto;
                            display: block;
                            width: 80%;
                            max-width: 600px;
                        }


                        .close {
                            position: absolute;
                            top: 15px;
                            right: 35px;
                            color: #f1f1f1;
                            font-size: 40px;
                            font-weight: bold;
                            transition: 0.3s;

                            &:hover, &:focus {
                                color: #bbb;
                                text-decoration: none;
                                cursor: pointer;
                            }
                        }


                    }
                } 

                .news-img-grid {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    padding: 20px 0px;
                    gap: 10px;

                    #img2, #img3, #img4, #img5 {
                        cursor: pointer;
                        max-width: 200px;
                        max-height: 200px;

                        &.none-display {
                            display: none;
                        }

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    .modal {
                        display: flex;
                        position: fixed;
                        /* Stay in place */
                        z-index: 21;
                        /* Sit on top */
                        padding-top: 100px;
                        /* Location of the box */
                        left: 0;
                        top: 0;
                        width: 100%;
                        /* Full width */
                        height: 100%;
                        /* Full height */
                        background-color: rgb(0, 0, 0);
                        /* Fallback color */
                        background-color: rgba(0, 0, 0, 0.9);
                        /* Black w/ opacity */


                        .modal-content {
                            margin: auto;
                            display: block;
                            width: 80%;
                            max-width: 600px;
                        }


                        .close {
                            position: absolute;
                            top: 15px;
                            right: 35px;
                            color: #f1f1f1;
                            font-size: 40px;
                            font-weight: bold;
                            transition: 0.3s;

                            &:hover,
                            &:focus {
                                color: #bbb;
                                text-decoration: none;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .news-paragraphs {
                width: 800px;

                p {
                    line-height: 30px;
                    font-size: 15px;
                    font-weight: 300;
                }
            }

            @media (max-width: 900px) {
                padding: 0px;
                text-align: center;

                .news-line {
                    width: 90%;
                }

                h2 {
                    font-size: 25px;
                }

                .news-images {

                    .news-main-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        #myImg{
                            width: 80%;
                        }
                    }

                    .news-img-grid {
                        width: 90%;
                        #img2,
                            #img3,
                            #img4,
                            #img5 {
                            max-width: 150px;
                            width: 90%;
                        }
                    }
                }

                .news-paragraphs {
                    width: 90%;
                }
            }
        }

        @media (max-width: 900px) {
            padding: 35px 0px 100px;
        }
    }
}

