.takk-heading {
    background-color: $bf-blue;
    color: white;
    text-align: center;
    text-transform: uppercase;

    h1 {
        font-size: 40px;
        text-align: center;
        padding: 30px;
        margin: 0;
        font-weight: 200;
        text-transform: uppercase;
    }

    @media (max-width: 850px) {
        h1 {
            font-size: 30px;
        }
    }
}

.takk-body {
    text-align: center;
    min-height: 50vh;
    height: auto;
    
    h2{
        padding: 50px 0px 40px 0px;

    }

    .takk-knapp {
        padding: 10px 25px;
        text-decoration: none;
        font-weight: 400;
        border: 1px $bf-green solid;
        color: white;
        background-color: #3FB66B;
        box-shadow: 0px 0px 5px 0px rgb(128 128 128 / 67%);
        transition: all 0.1s ease;

        &:hover {
            transition: all 0.1s ease;
            border: 1px $bf-blue solid;
            background-color: $bf-blue;
        }
    }
    
}