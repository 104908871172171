.contact-page {

    .contact-heading {
        background-color: $bf-blue;
        color: white;
        text-align: center;
        text-transform: uppercase;

        h1 {
            font-size: 40px;
            text-align: center;
            padding: 30px;
            margin: 0;
            font-weight: 200;
            text-transform: uppercase;
        }

        @media (max-width: 850px) {
            h1 {
                font-size: 30px;
            }
        }
    }


    .contact-section {
        background-image: url(../../images/kontakt-bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        height: auto;
        position: relative;
        padding: 60px 30px;


        .contact-content {
            max-width: 1000px;
            padding: 40px;
            background-color: white;
            margin: 0 auto;
            border-radius: 6px;

            h2 {
                text-align: center;
                font-size: 20px;
                margin-bottom: 10px;
                text-transform: uppercase;
            }

            .message-field {
                text-align: center;
                color: white;
                background-color: $bf-green;
                padding: 10px 0px;
            }
            
            .contact-split {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                width: 100%;
            
                .contact-form {
                    width: 50%;
                    padding-right: 30px;

                    form {

                        .form-container {
                            max-width: 450px;

                            .fields {
                                margin-top: 20px;
                            }

                            .btn {
                                margin-top: 30px;
                                color: white;
                                background-color: $bf-green;
                            }
                        }

                        
                    }
                }

                .contact-info {
                    padding-top: 30px;
                    width: 50%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .pt{
                        padding-top: 10px;
                    }

                    h4 {
                        margin-top: 20px;
                    }

                    .contact-potrett {
                        background-image: url(../../images/kontakt-bg.jpg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        height: 100px;
                        width: 100px;
                        border-radius: 20%;
                    }
                }
            }
        }

        @media (max-width: 600px) {
            .contact-content {

                h2 {
                    font-size: 18px;
                }

                .contact-split {
                    flex-direction: column;
                    align-items: center;

                    .contact-form {
                        padding-right: 0;
                        width: 100%;
                    }

                    .contact-info {
                        padding-top: 20px;
                    }
                }
            }
        }
    }
}