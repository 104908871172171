.hero {
    background-image: url(../../images/banefilter-hero-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    overflow: hidden !important;
    position: relative;

    .hero-content{
        padding: 150px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            max-width: 700px;
        }

    }

    @media (max-width: 850px) {
        
        .hero-content {
            padding: 100px 0px;
            img {
                max-width: 400px;

            }
        }
    }
}

.product-sec-main {
    width: 100%;
    padding: 0;
    margin: 0;

    .product-sec-main-heading{
        background-color: $bf-blue;
        color: white;

        h2{
            font-size: 40px;
            text-align: center;
            padding: 30px;
            margin: 0;
            font-weight: 200;
        }
    }

    .product-sec-main-products {
        max-width: 1200px;
        width: 100%;
        padding: 60px 0px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        margin: 0 auto;
        justify-items: center;

        .product-card {
            width: 270px;
            text-decoration: none;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                max-width: 270px;
                height: 203px;
            }

            .product-card-heading {
                width: 100%;
                height: 90px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0px 20px;
                background-color: $bf-blue;
                color: white;

                h3 {
                    font-size: 22px;
                }

                p{
                    font-weight: 100;
                    margin: 0;
                }
            }

            .product-card-price {
                width: 100%;
                color: black;
                background-color: $grey-bg;
                height: 250px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h3{
                    font-weight: 600;
                    margin: 0;
                    font-size: 25px;
                }

                p {
                    font-weight: 100;
                    margin-bottom: 80px;
                }

                button {
                    padding: 10px 25px;
                    border: none;
                    font-weight: 400;
                    color: white;
                    background-color: $bf-green;
                    box-shadow: 0px 0px 5px 0px #808080ab;
                    transition: all 0.1s ease;

                    &:hover {
                        transition: all 0.1s ease;
                        background-color: $bf-blue;
                    }

                }
            }
        }
        
    }

    @media (max-width: 1150px) {

        .product-sec-main-products {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            max-width: 600px;
            gap: 40px;
        }
    }

    @media (max-width: 850px) {
        
        .product-sec-main-heading {
            h2 {
                font-size: 30px;
                padding: 20px;
            }
        }
    }

    @media (max-width: 600px) {
        .product-sec-main-products {
            gap: 10px;
            max-width: 400px;
        

            .product-card {
                width: 170px;

                img {
                    max-width: 170px;
                    max-height: 135px;
                }

                .product-card-heading {
                    h3 {
                        font-size: 16px;
                    }

                    p {
                        font-size: 14px;
                    }
                }

                .product-card-price {
                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 14px;
                    }
                }

            }
        }

    }
}

.news-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px 100px;
    color: white;
    background-color: $news-bg;

    h2 {
        font-size: 40px;
        font-weight: 200;
        margin-bottom: 30px;
    }

    .popuplar-news-section {
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .popular-news-block {
            width: 100%;
            min-height: 250px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 50px 40px;
            background-size: cover;
            background-repeat: no-repeat;
            height: auto;
            overflow: hidden !important;
            position: relative;
            margin: 40px;
            border: solid white 3px;
            text-decoration: none;
            color: white;

            .left {
                width: 30%;
                img {
                    max-width: 200px;
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 70%;

                h2 {
                    font-weight: 500;
                    font-size: 28px;
                    text-shadow: 0px 0px 20px #000000c4;
                }

                button {
                    margin-top: 30px;
                    padding: 10px 25px;
                    border: none;
                    font-weight: 400;
                    color: white;
                    background-color: $bf-green;
                    box-shadow: 0px 0px 5px 0px #808080ab;
                    transition: all 0.1s ease;

                    &:hover {
                        transition: all 0.1s ease;
                        background-color: $bf-blue;
                    }

                }

            }
        }
    }

    @media (max-width: 850px) {

        h2{
            font-size: 30px;
            margin-bottom: 0;
        }

        .popuplar-news-section {

            .popular-news-block {
                flex-direction: column;
                height: auto;
                padding: 30px;
                min-height: auto;

                .left {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    img {
                        margin-bottom: 20px;
                    }
                }

                .right {
                    width: 100%;
                    h2 {
                        font-size: 25px;
                    }
                }
            }
        }

    }
}

.granulatfilter-section {
    background: linear-gradient(100deg, white 50%, $bf-blue 50%);
    padding: 50px;

    .granulatfilter-section-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 1500px;
        margin: 0 auto;

        .gr-filter-left{
            width: 60%;

            h1 {
                text-transform: uppercase;
                font-weight: bold;
                font-family: $primary-font;
            }

            p {
                max-width: 600px;
                padding-top: 20px;
                line-height: 40px;
            }

            button {
                margin-top: 50px;
            }
        }

        .gr-filter-right {
            width: 40%;
            display: flex;
            align-items: flex-start;
            justify-content: center;

            img {
                max-width: 350px;
                cursor: pointer;
            }

            .modal {
                display: flex;
                position: fixed;
                /* Stay in place */
                z-index: 21;
                /* Sit on top */
                padding-top: 100px;
                /* Location of the box */
                left: 0;
                top: 0;
                width: 100%;
                /* Full width */
                height: 100%;
                /* Full height */
                background-color: rgb(0, 0, 0);
                /* Fallback color */
                background-color: rgba(0, 0, 0, 0.9);
                /* Black w/ opacity */


                .modal-content {
                    margin: auto;
                    display: block;
                    width: 80%;
                    max-width: 800px;
                }


                .close {
                    position: absolute;
                    top: 15px;
                    right: 35px;
                    color: #f1f1f1;
                    font-size: 40px;
                    font-weight: bold;
                    transition: 0.3s;

                    &:hover,
                    &:focus {
                        color: #bbb;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }

    }

    @media (max-width: 1270px) {

        .granulatfilter-section-content {

            .gr-filter-left {

                p {
                    max-width: 400px;
                }
            }
        }
    }

    @media (max-width: 850px) {
        background: linear-gradient(360deg, white 55%, $bf-blue 45%);
        padding: 30px 30px 50px;

        .granulatfilter-section-content {
            flex-direction: column-reverse;
            align-items: center;
            text-align: center;

            .gr-filter-left {
                width: 100%;
                align-items: center;
                display: flex;
                flex-direction: column;

                h1{
                    font-size: 30px;
                    padding-top: 30px;
                }

                p {
                    margin: 0;
                }
                
            }

            .gr-filter-right {
                img {
                    margin-bottom: 50px;
                }
            }
        }
    }

}