.order-heading {
    background-color: $bf-blue;
    color: white;
    text-align: center;
    text-transform: uppercase;

    h1 {
        font-size: 40px;
        text-align: center;
        padding: 30px;
        margin: 0;
        font-weight: 200;
        text-transform: uppercase;
    }

    @media (max-width: 850px) {
        h1 {
            font-size: 30px;
        }
    }
}

.form-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3% 0;

    .form-block {

        .form-container {
            padding: 40px;
            box-shadow: 4px 9px 22px 0 rgb(0 0 0 / 13%);
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            .message-field {
                text-align: center;
                color: white;
                background-color: $bf-green;
                padding: 10px 0px;
                margin: 20px 0px;
            }

            .fields {
                display: block;
                padding-bottom: 15px;

                &.firma {
                    width: 100%;
                    display: flex;
                    flex-direction: row;

                    .field {
                        width: 100%;
                        padding-right: 20px;
                    }

                    @media (max-width: 600px) {
                        flex-direction: column;

                        .field {
                            padding-top: 15px;
                            padding-right: 0;
                        }
                    }
                }

                &.kontakt-person {
                    .field {
                        display: flex;
                        
                        input {
                            margin-right: 20px;
                        }

                        @media (max-width: 600px) {
                            flex-direction: column;

                            input {
                                margin-right: 0;
                                margin-top: 10px;
                            }
                        }
                    }
                }
        
                label {
                    font-weight: bold;
                    padding-bottom: 5px;
                    span {
                        color: red;
                    }
                }
            }
        }

        .order-products {
            padding: 50px 40px;
            
            h2{
                margin-bottom: 20px;
                font-weight: bold;
                color: $bf-blue;
                text-transform: uppercase;
            }
            
            .order-products-grid {
                display: grid;
                gap: 20px;
                grid-template-columns: 1fr 1fr 1fr;
                justify-content: center;
                margin: 0 auto;

                .order-boxes {
                    box-shadow: 4px 6px 9px 0 rgb(0 0 0 / 25%);
                    max-width: 300px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: left;
                    position: relative;
                    min-height: 420px;
                    border-radius: 10px;

                    &::after {
                        content: "";
                        width: 100%;
                        height: 20px;
                        border-radius: 0 0 10px 10px;
                        background-color: $bf-blue;
                        position: absolute;
                        bottom: 0;
                    }
            
                    img {
                        max-width: 300px;
                        max-height: 280px;
                        border-radius: 10px 10px 0 0;
                    }

                    .order-box-text {
                        padding: 0 0 20px 20px;
                        position: absolute;
                        bottom: 20px;
                        left: 0;

                        h3 {
                            font-size: 23px;
                            font-weight: bold;
                        }

                        p {
                            font-size: 18px;
                        }

                        .checkbox-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .checkbox {
                                width: 20px;
                                height: 20px;
                            }

                            .value-input {
                                margin-left: 10px;
                            }

                            .input-label {
                                display: none;
                            }
                        }

                        


                        .show {
                            display: none !important;
                        }
                    }
            
                }

                @media (max-width: 1055px) {
                    grid-template-columns: 1fr 1fr;
                }

                @media (max-width: 660px) {
                    .order-boxes {
                        width: 230px;
                        min-height: 375px;


                        img {
                            width: 230px;
                            max-height: 230px;
                        }

                        .order-box-text {
                            padding-right: 10px;
                            
                            h3{
                                font-size: 20px;
                            }

                            .fields {
                                input {
                                    max-width: 150px;
                                }
                            }
                        }
                    }
                }

                @media (max-width: 555px) {
                    .order-boxes {
                        width: 180px;
                        min-height: 335px;
                
                
                        img {
                            width: 180px;
                            max-height: 190px;
                        }
                
                        .order-box-text {
                            padding-right: 10px;
                
                            h3 {
                                font-size: 16px;
                            }

                            p {
                                font-size: 14px;
                            }

                            .fields{
                                input {
                                    max-width: 100px;
                                }
                            }
                        }
                    }
                }


            }

        }

        .button-form {
            padding-bottom: 50px;
            input {
                color: white;
                background-color: $bf-green;
                padding: 10px 20px;
                font-size: 20px;
                margin: 20px 0 0 40px;
                transition: all 0.1s ease;

                &:hover {
                    background-color: $bf-blue;
                    transition: all 0.1s ease;
                }
            }
            
        }

        
    }
}