nav {

    #hamburger-label {
        display: none;
        cursor: pointer;
        position: absolute;
        left: 30px;
        top: 45px;
        font-size: 30px;
        color: $bf-blue;
        z-index: 19;
    }

    .nav-top {
        position: relative;
        max-width: 800px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 auto;


        .nav-logo {
            img {
                padding-bottom: 20px;
            }
        }

        .cart-icon {
            position: absolute;
            right: 30px;
            bottom: -43px;
            font-size: 22px;
            color: $bf-blue;
            cursor: pointer;
            transition: all 0.1s ease;

            &:hover {
                transition: all 0.1s ease;
                color: $bf-green;
            }

            svg {
                padding-right: 5px;
            }
        }

        @media (max-width: 850px) {

            .cart-icon {
                bottom: 55px;
            }
        }

        @media (max-width: 600px) {
            .nav-logo {
                img {
                    padding-top: 20px;
                    max-width: 225px;
                }
            }

            .cart-icon {
                bottom: 50px;
            }
        }
    }

    @media (max-width: 850px) {
        #hamburger-label {
            display: block;
            transition: all 0.1 ease;
        }
    }

    
    #nav-web {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        max-width: 1200px;
        margin: 0 auto;
    
        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 650px;
            padding-left: none;
            padding-bottom: 10px;
    
            li {
                list-style: none;
    
                .nav-links {
                    text-decoration: none;
                    color: black;
                    font-size: 15px;
                    transition: all 0.1s ease;
    
                    &:hover {
                        transition: all 0.1s ease;
                        color: $bf-blue;
                    }
                }
            }
        }

        @media (max-width: 850px) {
            display: none;
        }

    }

    #nav-mob {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        z-index: 19;
        border-top: 3px solid $bf-blue;

        ul {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: 300px;
            height: 100%;
            padding-left: 0;
            position: absolute;
            background-color: white;
            z-index: 19;
            
            li {
                list-style: none;
                margin: 10px 0px;
                padding-left: 20px;
                padding-bottom: 5px;
                border-bottom: 1px solid #376fb673;
    
                .nav-links {
                    font-size: 20px;
                    text-decoration: none;
                    color: black;
                }
            }
        }

        .overlay-website {
            content: "";
            height: 100%;
            width: 100%;
            background-color: rgb(0 0 0 / 32%);
            z-index: 18;
            position: absolute;
        }
    }

    
}