.details-page {
    background-color: $bf-blue;
    color: white;

    .details-page-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 130  0px;
        width: 100%;
        margin: 0 auto;
        padding: 60px 30px 150px;

        .details-page-left {
            display: flex;
            flex-direction: column-reverse;
            width: 70%;

            .details-page-grid-img {
                display: flex;
                padding-top: 50px;
                flex-direction: column;

                .details-page-grid-img-upper {
                    display: flex;
                    justify-content: space-evenly;
                    padding-bottom: 40px;

                    img {
                        cursor: pointer;
                        max-width: 200px;
                        overflow: hidden;
                        width: 100%;
                        object-fit: cover;
                        max-height: 150px;
                        border-radius: 5px;
                
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    
                    .modal {
                        display: flex;
                        position: fixed;
                        /* Stay in place */
                        z-index: 21;
                        /* Sit on top */
                        padding-top: 100px;
                        /* Location of the box */
                        left: 0;
                        top: 0;
                        width: 100%;
                        /* Full width */
                        height: 100%;
                        /* Full height */
                        background-color: rgb(0, 0, 0);
                        /* Fallback color */
                        background-color: rgba(0, 0, 0, 0.9);
                        /* Black w/ opacity */
                
                
                        .modal-content {
                            margin: auto;
                            display: block;
                            width: 80%;
                            max-width: 600px;
                            max-height: none;
                        }
                
                        .img-text {
                            position: fixed;
                            left: 50%;
                            top: 90%;
                            transform: translateX(-50%);
                        }
                
                
                        .close {
                            position: absolute;
                            top: 15px;
                            right: 35px;
                            color: #f1f1f1;
                            font-size: 40px;
                            font-weight: bold;
                            transition: 0.3s;
                
                            &:hover,
                            &:focus {
                                color: #bbb;
                                text-decoration: none;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .details-page-grid-img-lower {
                    display: flex;
                    justify-content: space-evenly;

                    img {
                        cursor: pointer;
                        max-width: 200px;
                        overflow: hidden;
                        width: 100%;
                        object-fit: cover;
                        max-height: 150px;
                        border-radius: 5px;
                
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    
                    .modal {
                        display: flex;
                        position: fixed;
                        /* Stay in place */
                        z-index: 21;
                        /* Sit on top */
                        padding-top: 100px;
                        /* Location of the box */
                        left: 0;
                        top: 0;
                        width: 100%;
                        /* Full width */
                        height: 100%;
                        /* Full height */
                        background-color: rgb(0, 0, 0);
                        /* Fallback color */
                        background-color: rgba(0, 0, 0, 0.9);
                        /* Black w/ opacity */
                
                
                        .modal-content {
                            margin: auto;
                            display: block;
                            width: 80%;
                            max-width: 600px;
                            max-height: none;
                        }
                
                        .img-text {
                            position: fixed;
                            left: 50%;
                            top: 90%;
                            transform: translateX(-50%);
                        }
                
                
                        .close {
                            position: absolute;
                            top: 15px;
                            right: 35px;
                            color: #f1f1f1;
                            font-size: 40px;
                            font-weight: bold;
                            transition: 0.3s;
                
                            &:hover,
                            &:focus {
                                color: #bbb;
                                text-decoration: none;
                                cursor: pointer;
                            }
                        }
                    }
                }

                
            }
             
            
            .details-page-main-img {
                margin: 0 auto;
                align-items: center;
                display: flex;

                img {
                    cursor: pointer;
                    height: auto;
                    max-height: 550px;
                    max-width: 550px;
                    width: 100%;
                    border-radius: 5px;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .modal {
                    display: flex;
                    position: fixed;
                    /* Stay in place */
                    z-index: 21;
                    /* Sit on top */
                    padding-top: 100px;
                    /* Location of the box */
                    left: 0;
                    top: 0;
                    width: 100%;
                    /* Full width */
                    height: 100%;
                    /* Full height */
                    background-color: rgb(0, 0, 0);
                    /* Fallback color */
                    background-color: rgba(0, 0, 0, 0.9);
                    /* Black w/ opacity */


                    .modal-content {
                        margin: auto;
                        display: block;
                        width: 80%;
                        max-width: 600px;
                        max-height: none;
                    }

                    .img-text {
                        position: fixed;
                        left: 50%;
                        top: 90%;
                        transform: translateX(-50%);
                    }


                    .close {
                        position: absolute;
                        top: 15px;
                        right: 35px;
                        color: #f1f1f1;
                        font-size: 40px;
                        font-weight: bold;
                        transition: 0.3s;

                        &:hover,
                        &:focus {
                            color: #bbb;
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .details-right {
            width: 30%;
            

            .details-right-content {
                h2 {
                    margin-bottom: 20px;
                }

                h3 {
                    font-size: 35px;
                    margin-bottom: 20px;
                }

                .product-desc {
                    font-size: 15px;
                    font-weight: 300;
                    line-height: 25px;
                    padding-bottom: 25px;
                }

                .bestilling-knapp {
                    padding: 10px 25px;
                    text-decoration: none;
                    font-weight: 400;
                    border: 1px $bf-green solid;
                    color: white;
                    background-color: #3FB66B;
                    box-shadow: 0px 0px 5px 0px rgb(128 128 128 / 67%);
                    transition: all 0.1s ease;

                    &:hover {
                        transition: all 0.1s ease;
                        border: 1px white solid;
                        background-color: transparent;
                    }
                }
            }
        }

        @media (max-width: 620px) {
            .details-page-left {
                .details-page-grid-img {
                    width: 90%;

                    img {
                        max-width: 150px;
                    }
                }
            }
        }


        @media (max-width: 1190px) {
            flex-direction: column;
            text-align: left;

            .details-page-left{
                flex-direction: column-reverse;
                width: 100%;

                .details-page-grid-img {
                    grid-template-columns: 1fr 1fr 1fr;
                    margin: 0 auto;
                    padding: 30px 0px;
                    max-width: 500px;
                    width: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .details-page-main-img {
                    width: 90%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                }

            }

            .details-right {
                width: 90%;
                max-width: 500px;
            }
        }
    }

    .details-page-section-lower {
        max-width: 1300px;
        margin: 0 auto;
        padding: 30px;

        .seo-tekst-container {
            padding-bottom: 50px;
        }

        .seo-h2-container {
            padding-bottom: 50px;
        }

        .seo-h3-container{
            padding-bottom: 50px;
        }
    }

    .details-page-section-bottom {
        padding: 20px;

        .filtersize-images {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            img {
                max-height: 240px;
                padding: 10px;
            }

            @media (max-width: 1000px) {
                flex-direction: column;

                img {
                    max-width: 290px;
                }
            }
        }
    }
}