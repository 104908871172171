.oppsamling {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .about-heading {
        background-color: $bf-blue;
        color: white;
        text-align: center;
        text-transform: uppercase;

        h1 {
            font-size: 40px;
            font-weight: 200;
            margin: 0;
            padding: 30px;
            text-align: center;
            text-transform: none;
        }

        @media (max-width: 850px) {
            h1 {
                font-size: 30px;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        margin: 0 auto;

        .firstp-sec {
            padding-top: 50px;
        }

        .secp-sec {
            display: flex;
            flex-direction: row;
            padding-top: 40px;
            align-items: center;
            justify-content: center;    

            .left {
                padding-right: 50px;

                h2 {
                    font-weight: bold;
                }
            }

            .right {
                img {
                    max-height: 400px;
                    width: auto;
                    object-fit: cover;
                    border-radius: 20px;
                }
            }
        }

        .thirdp-sec {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-top: 100px;
            padding-bottom: 100px;
            
            .left {
                padding-right: 50px;
                width: 50%;
        
                h3 {
                    font-weight: bold;
                }
            }
        
            .right {
                width: 50%;
                img {
                    max-height: 400px;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 20px;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .content {
            .firstp-sec {
                text-align: center;
                padding: 50px 20px;
            }

            .secp-sec {
                flex-direction: column;
                padding: 50px 20px;

                .left {
                    text-align: center;
                    padding-bottom: 40px;
                    padding-right: 0px;
                }
            }

            .thirdp-sec {
                flex-direction: column;
                padding: 50px 20px;

                .left {
                    text-align: center;
                    padding-bottom: 40px;
                    padding-right: 0px;
                    width: auto;
                }

                .right {
                    width: auto;
                }
            }
        }
    }
}