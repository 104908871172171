body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;;
}

.cta-btn {
    margin-top: 30px;
    padding: 15px 40px;
    font-size: 17px;
    font-weight: 400;
    font-family: $primary-font;
    background-color: $bf-green;
    border-radius: 5px;
    border: none;
    color: white;
    text-shadow: 1px 1px 20px #00000084;
    transition: all .3s ease;

    &:hover {
        transition: all .3s ease;
        background-color: $bf-blue;
    }
}

#loader-div{
    display: flex;
    padding: 50px 0px;
    width: 100%;
}

#loader, #loader1 {
    max-width: 100px;
    margin: 0 auto;
    animation: spin 1.5s infinite ease;
    width: 100%;
}

@keyframes spin {
    from {
        transform: rotateZ(360deg);
    }
    to {
        transform: rotateZ(0deg);
    }
}